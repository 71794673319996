import { candidateReportService } from "api";
import { Preloader, Toast } from "components";
import { CandidateReportUI } from "features";
import { copyToClipBoard } from "helpers";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const CandidateReport = () => {
  const { id, id3, tab } = useParams();
  const {
    run: runReportData,
    data: reportResponse,
    requestStatus: reportRequestStatus,
    error: tableError,
  } = useApiRequest({});
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [title, setTitle] = useState("");
  const [fullReport, setFullReport] = useState<any>(null);
  useMemo(() => {
    if (reportResponse?.status === 200) {
      const report = reportResponse.data.results;
      const fullReportList: any = report.find((item: any) => item.id === Number(id3));
      console.log(fullReportList)
      setFullReport(fullReportList)
    } else if (tableError) {
      setToast({
        show: true,
        head: "Error",
        message: tableError.message,
      });
    }
  }, [id3, reportResponse, tableError]);
  useEffect(() => {
    runReportData(
      candidateReportService(
        {
          id: id,
          tab: tab
        }
      )
    );
  }, [
    id,
    runReportData,
    tab
  ]);

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  const copyLink = () => {
    const onSuccess = () => {
      setToast({
        show: true,
        head: "Success",
        message: "Link copied to clipboard successfully!",
      });
    };

    const onError = () => {
      alert("Failed to copy link to clipboard.");
      setToast({
        show: true,
        head: "Error",
        message: "Failed to copy link to clipboard.",
      });
    };

    copyToClipBoard({
      link: fullReport?.portfolio,
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  const isValidURL = (str: string): boolean => { try { return !!new URL(str); } catch { return false; } };

  const viewCV = () => {
    if (fullReport?.resume && isValidURL(fullReport?.resume)) {
      window.open(fullReport?.resume, '_blank');
    } else {
      setToast({
        show: true,
        head: "Error",
        message: "CV is invalid",
      });
    }
  };

  return (
    <div>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={reportRequestStatus.isPending} />
      <CandidateReportUI
        score={(Math.floor(fullReport?.total_score)).toString()}
        name={fullReport?.full_name}
        email={fullReport?.email}
        phoneNumber={fullReport?.phone_number}
        copyLink={copyLink}
        viewCV={viewCV}
      />
    </div>
  );
};

export { CandidateReport };
