import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  BarChart,
  FilterTabs,
  GroupTab,
  MiniCard,
  Pagination,
  ReportsFilter,
  ReportsFilterData,
  ReportsTable,
  ReportsTableItem,
  Search,
  Table,
} from "components";
import {
  ArrowIcon,
  ArrowLeftIcon,
  CandidateCardIcon,
  EmptyTable,
  UsersIcon,
} from "assets";
import { Routes } from "router";
import { EmptyComponent } from "../employers";
import { optionType } from "types";

interface ReportsProps {
  reportsTable: ReportsTableItem[];
  reportSearchTerm: string;
  handleReportSearch: (data: any) => void;
  handleReportFilter: (data: any) => void;
  reportPagination: {
    handleChange: (page: any) => void;
    total: number;
    current: number;
    limit: number;
  };
  filter: ReportsFilterData;
  handleReportView: (id: any, id2: any) => void;
  reportChartData: number[];
  reportChartLabels: any[];
  report: (data: any) => void;
  title: string;
  filterOptions: any[];
  handleFilterClick: (data: optionType) => void;
  handleCandidateFilterClick: (data: optionType) => void;
  period: optionType;
  candidatePeriod: optionType;
  carousel: {
    average_precision_score: {
      count: string;
      growth: string;
    };
    total_assessed_roles: {
      count: string;
      growth: string;
    };
    completed_responses: {
      count: string;
      growth: string;
    };
    abandoned_responses: {
      count: string;
      growth: string;
    };
    total_candidates: {
      count: string;
      growth: string;
    };
    total_candidates_consider: {
      count: string;
      growth: string;
    };
    total_candidates_rejected: {
      count: string;
      growth: string;
    };
    total_candidates_to_interview: {
      count: string;
      growth: string;
    };
    total_candidates_accepted: {
      count: string;
      growth: string;
    };
  };
}

const ReportsUI: React.FC<ReportsProps> = ({
  reportSearchTerm,
  handleReportSearch,
  handleReportFilter,
  reportPagination,
  reportsTable,
  reportChartData,
  reportChartLabels,
  report,
  handleReportView,
  title,
  filterOptions,
  handleFilterClick,
  period,
  carousel,
  handleCandidateFilterClick,
  candidatePeriod,
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const reportsTableHeaderTitles = [
    {
      index: 1,
      title: "Assessments title",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Start date",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "Status",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Total candidates",
      Icon: EmptyComponent,
    },
    {
      index: 5,
      title: "Completed",
      Icon: EmptyComponent,
    },
    {
      index: 6,
      title: "Abandoned",
      Icon: EmptyComponent,
    },
    {
      index: 7,
      title: "Deadline",
      Icon: EmptyComponent,
    },
  ];
  const candidatesCardData = [
    {
      id: 1,
      header: "Total candidates",
      users: carousel.total_candidates.count,
      timeFrame: period.label,
      type: carousel.total_candidates.growth,
    },
    {
      id: 2,
      header: "Total candidates: Consider",
      users: carousel.total_candidates_consider.count,
      timeFrame: period.label,
      type: carousel.total_candidates_consider.growth,
    },
    {
      id: 3,
      header: "Total candidates: Rejected",
      users: carousel.total_candidates_rejected.count,
      timeFrame: period.label,
      type: carousel.total_candidates_rejected.growth,
    },
    {
      id: 3,
      header: "Total candidates: To interview",
      users: carousel.total_candidates_to_interview.count,
      timeFrame: period.label,
      type: carousel.total_candidates_to_interview.growth,
    },
    {
      id: 4,
      header: "Total candidates: Accepted",
      users: carousel.total_candidates_accepted.count,
      timeFrame: period.label,
      type: carousel.total_candidates_accepted.growth,
    },
  ];
  const cardData = [
    {
      id: 1,
      header: "Precision score",
      users: carousel.average_precision_score.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.average_precision_score.growth
    },
    {
      id: 2,
      header: "Total abandoned",
      users: carousel.abandoned_responses.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.abandoned_responses.growth
    },
    {
      id: 3,
      header: "Total completed",
      users: carousel.completed_responses.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.completed_responses.growth
    },
    {
      id: 3,
      header: "Total assessed roles",
      users: carousel.total_assessed_roles.count,
      timeFrame: period.label,
      usersRate: 10,
      ratingType: carousel.total_assessed_roles.growth
    },
  ];
  const [headerVisibility, setHeaderVisibility] = useState(
    reportsTableHeaderTitles.map(() => true)
  );
  const shouldHideTable = headerVisibility
    .slice(0, -1)
    .every((visible) => !visible);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: -scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };
  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const [activePeriod, setActivePeriod] = useState("oneYear");
  return (
    <>
      <GroupTab
        title={title}
        description="Analyze comprehensive data on assessments, including detailed role-specific trends and performance metrics."
        back={false}
        navigateBack={() => {
          navigate(Routes.reports(id));
        }}
        active={"reports"}
        id={id}
        navigateProfile={() => {
          navigate(Routes.profile(id));
        }}
        navigateAssessments={() => {
          navigate(Routes.assessments("published", id));
        }}
        navigateReports={() => {
          navigate(Routes.reports(id));
        }}
        items={[
          {
            Icon: UsersIcon,
            label: "Users",
            link: "",
          },
          {
            Icon: EmptyComponent,
            label: "Employers",
            link: Routes.employers,
          },
          {
            Icon: EmptyComponent,
            label: title,
            link: Routes.profile(id),
          },
        ]}
      />
      <FilterTabs
        filters={filterOptions}
        onChange={handleFilterClick}
        value={period}
        className={styles.filterTabs}
      />
      <div className={`${styles.reports}`}>
        <div className={styles.cards}>
          <div className={styles.cardScroll}>
            {cardData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                timeFrame={item.timeFrame}
              />
            ))}
          </div>
        </div>
        <div className={styles.candidateInfo}>
          <div className={styles.candidateHead}>
            <h3 className={styles.candidateTitle}>Candidates Information</h3>
            <div className={styles.right}>
              <div className={styles.leftArrow} onClick={scrollLeft}>
                <ArrowLeftIcon />
              </div>
              <div className={styles.rightArrow} onClick={scrollRight}>
                <ArrowLeftIcon />
              </div>
            </div>
          </div>
          {/* <FilterTabs
            filters={filterOptions}
            onChange={handleFilterClick}
            value={period}
            className={styles.candidateFilterTabs}
          /> */}
          <div className={styles.candidateCards} ref={scrollRef}>
            {candidatesCardData.map((item, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.left}>
                  <div className={styles.icon}>
                    <CandidateCardIcon />
                  </div>
                  <div className={styles.texts}>
                    <p className={styles.textTitle}> {item.header} </p>
                    <h2 className={styles.textNo}> {item.users} </h2>
                  </div>
                </div>
                <div className={styles.rate}>
                  <div
                    className={`
                      ${styles[item.type.toLowerCase()]}
                      `}
                  >
                    <ArrowIcon
                      className={`
                        ${styles[item.type.toLowerCase() + "Arrow"]}
                        `}
                    />
                    <p
                      className={`${styles[item.type.toLowerCase() + "Desc"]}`}
                    >
                      {item.type}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.chart}>
          <div className={styles.chartHead}>Frequently assessed roles</div>
          <div className={styles.chartGraph}>
            <BarChart dataProp={reportChartData} labels={reportChartLabels} />
          </div>
          <div className={styles.chartLine}>
            <span
              className={`${styles.date} ${
                activePeriod === "oneDay" ? styles.active : ""
              }`}
              onClick={() => {
                report("oneDay");
                setActivePeriod("oneDay");
              }}
            >
              1D
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "oneWeek" ? styles.active : ""
              }`}
              onClick={() => {
                report("oneWeek");
                setActivePeriod("oneWeek");
              }}
            >
              1W
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "oneMonth" ? styles.active : ""
              }`}
              onClick={() => {
                report("oneMonth");
                setActivePeriod("oneMonth");
              }}
            >
              1M
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "threeMonth" ? styles.active : ""
              }`}
              onClick={() => {
                report("threeMonth");
                setActivePeriod("threeMonth");
              }}
            >
              3M
            </span>
            <span 
              className={`${styles.date} ${
                activePeriod === "oneYear" ? styles.active : ""
              }`}
              onClick={() => {
                report("oneYear");
                setActivePeriod("oneYear");
              }}
            >
              1Y
            </span>
            <span
              className={`${styles.date} ${
                activePeriod === "all" ? styles.active : ""
              }`}
              onClick={() => {
                report("all");
                setActivePeriod("all");
              }}
            >
              All
            </span>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableBar}>
            <h3 className={styles.head}>Assessments</h3>
            <div className={styles.tableActions}>
              <div className={styles.filterScroll}>
                <ReportsFilter
                  submit={handleReportFilter}
                  className={styles.filter}
                  show="reports"
                />
              </div>
              <Search
                className={styles.search}
                value={reportSearchTerm}
                placeholder="Search in list..."
                handleChange={(e) => {
                  handleReportSearch(e);
                }}
              />
            </div>
          </div>
          {shouldHideTable ? (
            <div className={styles.emptyTable}>
              <EmptyTable />
            </div>
          ) : (
            <Table
              tableHeaderTitles={reportsTableHeaderTitles}
              tableBody={
                <ReportsTable
                  tableBody={styles.tableBody}
                  tableBodyItems={reportsTable}
                  tableBodyItemClassName={styles.tableItem}
                  tableBodyRowClassName={styles.reportTableBodyItem}
                  tableBodyStatus={styles.tableBodyStatus}
                  shownHeaders={reportsTableHeaderTitles}
                  statusItem={styles.statusItem}
                  view={(id2) => {
                    console.log(id2);
                    handleReportView(id, id2);
                  }}
                />
              }
              customTableClasses={{
                tableContainerClassName: styles.reportTableWrap,
                tableHeaderClassName: styles.reportTableHeader,
                tableHeaderItemClassName: styles.tableHeaderItem,
                statusClass: styles.statusClass,
                headerStyle: styles.headerStyle,
              }}
              headerVisibility={headerVisibility}
            />
          )}
          <Pagination
            current={reportPagination.current}
            total={reportPagination.total}
            handleChange={reportPagination.handleChange}
            limit={reportPagination.limit}
            parentClassName={styles.pagin}
            classP={styles.paginationBtn}
            paginText={styles.paginText}
          />
        </div>
      </div>
    </>
  );
};

export { ReportsUI };
