import {
  BreadCrumbs,
  Button,
  CandidatesTable,
  CandidatesTableItem,
  DoughnutChart,
  FilterTabs,
  MiniCard,
  Pagination,
  ProgressSelect,
  Search,
  SelectDropDown,
  Table,
} from "components";
import styles from "./styles.module.scss";
import {
  ArrowIcon,
  ArrowLeftIcon,
  CopyIcon,
  DotIcon,
  EmptyTable,
  RefreshIcon,
  SelectIcon,
  TrashIcon,
  UsersIcon,
} from "assets";
import { EmptyComponent } from "../employers";
import { Routes } from "router";
import { useRef, useState } from "react";
import { Plugin } from "chart.js";
import { optionType } from "types";

interface CandidatesProps {
  candidates: CandidatesTableItem[];
  searchTerm: string;
  handleSearch: (data: string) => void;
  pagination: {
    handleChange: (page: number) => void;
    total: number;
    current: number;
    limit: number;
  };
  sort: () => void;
  checks: boolean[];
  setChecks: (check: boolean[]) => void;
  assessmentID: string;
  candidateID: string;
  resetTime: (id: string, assessmentID: string) => void;
  restartAssessment: (id: string, assessmentID: string) => void;
  deleteCandidates: (candidateID: string, assessmentID: string) => void;
  copyLink: (id: string) => void;
  requestReview: (id: string, assessmentID: string) => void;
  handleDuration: (time: any) => void;
  progress: (id: string) => void;
  carousel:{
    overall_precision_score: {
      count: string;
      growth: string;
    };
    total_candidate_population: {
      count: string;
      growth: string;
    };
    total_completed_tests: {
      count: string;
      growth: string;
    };
    total_abandoned_tests: {
      count: string;
      growth: string;
    };
    total_avg_time_spent: {
      count: string;
      growth: string;
    };
  };
  list: any[];
  assessmentId: (id: any) => void;
  totalCandidates: string;
  continuers: string;
  retakers: {
    count: string;
    growth: string;
  };
  filterOptions: any[];
  handleFilterClick: (data: optionType) => void;
  period: optionType;
}

const CandidatesUI: React.FC<CandidatesProps> = ({
  candidates,
  searchTerm,
  handleSearch,
  pagination,
  sort,
  checks,
  setChecks,
  candidateID,
  assessmentID,
  resetTime,
  restartAssessment,
  deleteCandidates,
  copyLink,
  requestReview,
  handleDuration,
  progress,
  carousel,
  list,
  assessmentId,
  totalCandidates,
  continuers,
  retakers,
  filterOptions,
  handleFilterClick,
  period,
}) => {
  const tableHeaderTitles = [
    {
      index: 1,
      title: "Name",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Assessment title",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "Assessment ID",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Company",
      Icon: EmptyComponent,
    },
    {
      index: 5,
      title: "Phone no.",
      Icon: ArrowIcon,
    },
    {
      index: 6,
      title: "Progress",
      Icon: EmptyComponent,
    },
    {
      index: 7,
      title: "Dots",
      Icon: EmptyComponent,
    },
  ];
  const cardData = [
    {
      id: 1,
      header: "Overall Precision ccore",
      users: carousel.overall_precision_score.count,
      timeFrame: period.label,
      percentage: true,
      ratingType: carousel.overall_precision_score.growth
    },
    {
      id: 2,
      header: "Total candidate population",
      users: carousel.total_candidate_population.count,
      timeFrame: period.label,
      ratingType: carousel.total_candidate_population.growth
    },
    {
      id: 3,
      header: "Completed tests",
      users: carousel.total_completed_tests.count,
      timeFrame: period.label,
      ratingType: carousel.total_completed_tests.growth
    },
    {
      id: 4,
      header: "Abandoned tests",
      users: carousel.total_abandoned_tests.count,
      timeFrame: period.label,
      ratingType: carousel.total_abandoned_tests.growth
    },
    {
      id: 5,
      header: "Average time spent",
      users: carousel.total_avg_time_spent.count,
      timeFrame: period.label,
      ratingType: carousel.total_avg_time_spent.growth
    },
  ];
  const checkTrue = checks.filter((value) => value === true);
  const handleCheck = ({ index, value, assessmentID }: { index: number; value: boolean, assessmentID: any }) => {
    const newChecks = [...checks];
    newChecks[index] = value;
    setChecks(newChecks);
    assessmentId(assessmentID);
  };
  const [headerVisibility, setHeaderVisibility] = useState(
    tableHeaderTitles.map(() => true)
  );
  const shouldHideTable = headerVisibility
    .slice(0, -1)
    .every((visible) => !visible);
  const shownHeaders = tableHeaderTitles.filter(
    (_, index) => headerVisibility[index]
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: -scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const items = () => [
    {
      name: "Daily",
      action: () => {
        handleDuration("one_day");
      },
    },
    {
      name: "Monthly",
      action: () => {
        handleDuration("one_month");
      },
    },
    {
      name: "Yearly",
      action: () => {
        handleDuration("one_year");
      },
    },
  ];

  const progressItems = [
    {
      name: "Abandoned",
      icon: DotIcon,
      action: () => {
        progress("Abandoned");
      },
    },
    {
      name: "Completed",
      icon: DotIcon,
      action: () => {
        progress("Completed");
      },
    },
    {
      name: "Started",
      icon: DotIcon,
      action: () => {
        progress("Started");
      },
    },
  ];

  const centerTextPlugin: Plugin<"doughnut"> = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      const number = chart.data.datasets[0].data[0] as number;
      const text = `${number.toString()}`;
      const text2 = `candidates`;

      ctx.save();

      ctx.fillStyle = "#101323";
      const fontSizeNumber = (height / 6).toFixed(2);
      ctx.font = `${fontSizeNumber}px Gelion`;
      ctx.textBaseline = "middle";

      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 10; // Adjust the value to position the number

      ctx.fillText(text, textX, textY);

      ctx.fillStyle = "#667085";
      const fontSizeText2 = (height / 13).toFixed(2);
      ctx.font = `300 ${fontSizeText2}px Gelion`;
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
      const text2Y = textY + parseInt(fontSizeText2) + 10; // Adjust the value to position the text below the number

      ctx.fillText(text2, text2X, text2Y);

      ctx.restore();
    },
  };
  return (
    <>
      <div className={styles.candidates}>
        <BreadCrumbs
          className={styles.navItem}
          items={[
            {
              Icon: UsersIcon,
              label: "Users",
              link: "",
            },
            {
              Icon: EmptyComponent,
              label: "Candidates",
              link: Routes.candidates,
            },
          ]}
        />
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.head}>Users</h3>
            <p className={styles.subHead}>
              Manage and track candidates assessments effortlessly from a single, intuitive dashboard.
            </p>
          </div>
          <div className={styles.right}>
            <div className={styles.leftArrow}>
              <ArrowLeftIcon onClick={scrollLeft} />
            </div>
            <div className={styles.rightArrow} onClick={scrollRight}>
              <ArrowLeftIcon />
            </div>
          </div>
        </div>
        <FilterTabs
          filters={filterOptions}
          onChange={handleFilterClick}
          value={period}
          className={styles.filterTabs}
        />
        <div className={styles.cards}>
          <div className={styles.cardScroll} ref={scrollRef}>
            {cardData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                className={styles.card}
                percentage={item.percentage}
                timeFrame={item.timeFrame}
              />
            ))}
          </div>
        </div>
        <div className={styles.bigCards}>
          <div className={styles.left}>
            <div className={styles.headBar}>
              <h3 className={styles.headd}>Candidates</h3>
            </div>
            <div className={styles.chart}>
              <DoughnutChart
                score={totalCandidates}
                radius={[0]}
                cutout="80%"
                total={600}
                color={["#605BFF", "#FFF5E6"]}
                plugins={[centerTextPlugin]}
              />
            </div>
            <div className={styles.foot}>
              {/* <div className={styles.leftFoot}>
                <div className={`${styles.new}`}>
                  <ArrowIcon className={`${styles.newArrow}`} />
                </div>
                <p className={`${styles.newDesc}`}>
                  {continuers}
                  <span className={styles.cont}>Continuers</span>
                </p>
              </div> */}
              <div className={styles.leftFoot}>
                <div className={`${styles.new}`}>
                  <ArrowIcon className={`${styles[retakers.growth.toLowerCase()+'Arrow']}`} />
                </div>
                <p className={`${styles.desc}`}>
                  <span className={`${styles[retakers.growth.toLowerCase()+'Number']}`}>{retakers.count}</span>
                  <span className={styles[retakers.growth.toLowerCase()+'Cont']}>Retakers</span>
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.left} ${styles.right}`}>
            <div className={styles.headBar}>
              <h3 className={styles.headd}>Average time spent</h3>
              <SelectDropDown items={items()} subClassName={styles.select} />
            </div>
            <div className={styles.body}>
              {list.map((item, index) => (
                <div key={index} className={styles.bodyBar}>
                  <div className={styles.title}>{item.assessment_title}</div>
                  <div className={styles.time}>{item.average_time_spent}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableBar}>
            <h3 className={styles.head}>All Candidates</h3>
            <div className={styles.tableActions}>
              <ProgressSelect
                className={styles.progressSelect}
                items={progressItems}
                title="Progress"
              />
              <Search
                className={styles.search}
                value={searchTerm}
                placeholder="Search in list..."
                handleChange={(e) => {
                  handleSearch(e);
                }}
              />
            </div>
          </div>
          {shouldHideTable ? (
            <div className={styles.emptyTable}>
              <EmptyTable />
            </div>
          ) : (
            <Table
              tableHeaderTitles={shownHeaders}
              tableBody={
                <CandidatesTable
                  tableBodyItems={candidates}
                  tableBodyItemClassName={styles.tableItem}
                  tableBodyRowClassName={styles.tableBodyItem}
                  tableBodyStatus={styles.tableBodyStatus}
                  shownHeaders={shownHeaders}
                  statusItem={styles.statusItem}
                  checks={checks}
                  handleCheck={handleCheck}
                  handleDelete={deleteCandidates}
                  handleRequestReview={requestReview}
                />
              }
              customTableClasses={{
                tableContainerClassName: styles.tableWrap,
                tableHeaderClassName: styles.tableHeader,
                tableHeaderItemClassName: styles.tableHeaderItem,
                statusClass: styles.statusClass,
              }}
              headerVisibility={headerVisibility}
              sort={sort}
            />
          )}
          <Pagination
            current={pagination.current}
            total={pagination.total}
            handleChange={pagination.handleChange}
            limit={pagination.limit}
            parentClassName={styles.pagin}
            classP={styles.paginationBtn}
            paginText={styles.paginText}
          />
        </div>
        {checkTrue.length !== 0 && (
          <div className={styles.employerSelected}>
            <div className={styles.amount}>
              {checkTrue.length} candidate{checkTrue.length > 1 && "s"} selected
            </div>
            <div className={styles.buttons}>
              <Button
                type="transparent"
                onClick={() => {
                  resetTime(candidateID, assessmentID);
                }}
                className={styles.deleteBtn}
              >
                <RefreshIcon />
                Reset time
              </Button>
              <Button
                type="transparent"
                onClick={() => {
                  restartAssessment(candidateID, assessmentID);
                }}
                className={styles.deleteBtn}
              >
                <RefreshIcon />
                Restart assessment
              </Button>
              <Button
                type="fill"
                onClick={() => {
                  copyLink(assessmentID);
                }}
                className={styles.requestBtn}
              >
                <CopyIcon />
                Copy link
              </Button>
              <Button
                type="transparent"
                onClick={() => {
                  deleteCandidates(candidateID, assessmentID);
                }}
                className={styles.deleteBtn}
              >
                <TrashIcon />
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { CandidatesUI };
