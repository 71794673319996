import styles from "./styles.module.scss";
import { CandidatesIcon, CloseIcon, FlagIcon } from "assets";
import * as yup from "yup";
import { SubmitHandler } from "react-hook-form";
import { Button, CheckBox, Input, InputDropDown, Modal } from "components";

export interface EmployersFilterData {
  regular?: boolean | null;
  pro?: boolean | null;
  payg?: boolean | null;
  freeTrial?: boolean | null;
  candidatesMin?: string | null;
  candidatesMax?: string | null;
  assessedMin?: string | null;
  assessedMax?: string | null;
  createdMin?: string | null;
  createdMax?: string | null;
  active?: boolean | null;
  paused?: boolean | null;
  terminated?: boolean | null;
}

export const schema = yup.object({
  regular: yup.boolean(),
  pro: yup.boolean(),
  payg: yup.boolean(),
  freeTrial: yup.boolean(),
  candidatesMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  candidatesMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Max value should only contain numbers"),
  assessedMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  assessedMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Max value should only contain numbers"),
  createdMin: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Min value should only contain numbers"),
  createdMax: yup
    .string()
    .nullable()
    .matches(/[0-9]/, "Max value should only contain numbers"),
  active: yup.boolean(),
  paused: yup.boolean(),
  terminated: yup.boolean(),
});

interface EmployersFilterModalProps {
  show: boolean;
  close: () => void;
  submit: (data: EmployersFilterData) => void;
  form: any;
}

export const initFilterData: EmployersFilterData = {
  regular: false,
  pro: false,
  payg: false,
  freeTrial: false,
  candidatesMin: null,
  candidatesMax: null,
  assessedMin: null,
  assessedMax: null,
  createdMin: null,
  createdMax: null,
  active: false,
  paused: false,
  terminated: false,
};

const EmployersFilterModal: React.FC<EmployersFilterModalProps> = ({
  show,
  close,
  submit,
  form,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
  } = form;

  const onSubmit: SubmitHandler<EmployersFilterData> = (data) => {
    submit(data);
    submit(form.watch());
    close();
    console.log("hjhv");
  };
  const onReset = () => {
    reset(initFilterData);
    submit(initFilterData);
    close();
  };

  return (
    <>
      <Modal
        contentClassName={styles.modal}
        position={"centered"}
        close={close}
        show={show}
      >
        <div className={styles.close}>
          <CloseIcon
            className={styles.closeIcon}
            role="button"
            onClick={close}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.header}>
            <p>Filters</p>
          </div>
          <form className={styles.form}>
            <InputDropDown
              Icon={FlagIcon}
              className={styles.wrapStatus} title="Plan type">
              <div className={`${styles.item} ${styles.firstItem}`}>
                <CheckBox
                  id={""}
                  checked={watch(`regular`)}
                  onChange={() => {
                    setValue(`regular`, !watch(`regular`));
                  }}
                />
                <span>Regular</span>
              </div>
              <div className={styles.item}>
                <CheckBox
                  id={""}
                  checked={watch(`pro`)}
                  onChange={() => {
                    setValue(`pro`, !watch(`pro`));
                  }}
                />
                <span>Pro</span>
              </div>
              <div className={styles.item}>
                <CheckBox
                  id={""}
                  checked={watch(`payg`)}
                  onChange={() => {
                    setValue(`payg`, !watch(`payg`));
                  }}
                />
                <span>PAYG</span>
              </div>
              <div className={styles.item}>
                <CheckBox
                  id={""}
                  checked={watch(`freeTrial`)}
                  onChange={() => {
                    setValue(`freeTrial`, !watch(`freeTrial`));
                  }}
                />
                <span>Free trial</span>
              </div>
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap} title="No of candidates">
                <Input
                  placeholder="Min"
                  name="candidatesMin"
                  type="number"
                  validatorMessage={errors.candidatesMin?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
                <span className={styles.dash}>-</span>
                <Input
                  placeholder="Max"
                  name="candidatesMax"
                  type="number"
                  validatorMessage={errors.candidatesMax?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap} title="No of created roles">
                <Input
                  placeholder="Min"
                  name="createdMin"
                  type="number"
                  validatorMessage={errors.createdMin?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
                <span className={styles.dash}>-</span>
                <Input
                  placeholder="Max"
                  name="createdMax"
                  type="number"
                  validatorMessage={errors.createdMax?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
            </InputDropDown>
            <InputDropDown
              Icon={CandidatesIcon}
              className={styles.wrap} title="No roles assessed">
                <Input
                  placeholder="Min"
                  name="assessedMin"
                  type="number"
                  validatorMessage={errors.assessedMin?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
                <span className={styles.dash}>-</span>
                <Input
                  placeholder="Max"
                  name="assessedMax"
                  type="number"
                  validatorMessage={errors.assessedMax?.message}
                  parentClassName={styles.input}
                  className={styles.mainInput}
                  register={register}
                />
            </InputDropDown>
            <InputDropDown
              Icon={FlagIcon}
              className={styles.wrapStatus} title="Status">
                <div className={`${styles.item} ${styles.firstItem}`}>
                  <CheckBox
                    id={""}
                    checked={watch(`active`)}
                    onChange={() => {
                      setValue(`active`, !watch(`active`));
                    }}
                  />
                  <span>Active</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`paused`)}
                    onChange={() => {
                      setValue(`paused`, !watch(`paused`));
                    }}
                  />
                  <span>Paused</span>
                </div>
                <div className={styles.item}>
                  <CheckBox
                    id={""}
                    checked={watch(`terminated`)}
                    onChange={() => {
                      setValue(`terminated`, !watch(`terminated`));
                    }}
                  />
                  <span>Terminated</span>
                </div>
            </InputDropDown>
          </form>
          <div className={styles.footer}>
            <Button
              className={styles.cancelbtn}
              type="outline"
              onClick={onReset}
            >
              Cancel
            </Button>
            <Button type="fill" onClick={handleSubmit(onSubmit)}>
              Apply filters
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { EmployersFilterModal };
