import React from 'react'
import styles from "./styles.module.scss"
import { ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";

interface BarChartProps {
  className?: string;
  dataProp: number[],
  labels: string[],
}

const BarChart: React.FC<BarChartProps> = ({
  className,
  dataProp,
  labels,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: dataProp,
        backgroundColor: [
          "#605BFF",
          "#605BFF",
          "#605BFF",
          "#605BFF",
          "#605BFF",
          "#605BFF",
        ],
        pointBorderColor: "black",
        fill: true,
        pointRadius: 1,
        borderWidth: 0,
        barPercentage: 1.2,
      },
    ],
  };
  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          stepSize: 25,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  return (
    <div className={`${styles.chartGraph} ${className}`}>
      <Bar data={data} options={options}></Bar>
    </div>
  )
}

export {BarChart}
