import { ArrowIcon } from "assets";
import styles from "./styles.module.scss";
interface MiniCardData {
  users: number | string;
  percentage?: boolean;
  header: string;
  ratingType: string;
  timeFrame?: string;
}

interface CardProps extends MiniCardData {
  onClick?: (name: string) => void;
  openChart: () => void;
  className?: string;
}

const MiniCard: React.FC<CardProps> = ({
  ratingType,
  users,
  percentage,
  header,
  className,
  timeFrame
}) => {
  return (
    <div className={`${className} ${styles.users}`}>
      <div className={styles.usersHead}>
        <p className={styles.head}>{header}</p>
      </div>
      <p className={styles.number}>{users}{percentage && `%`}</p>
      <div className={styles.rate}>
        <div className={styles[ratingType?.toLowerCase()]}>
          <ArrowIcon className={styles[ratingType?.toLowerCase()+'Arrow']}/>
          <p className={styles[ratingType?.toLowerCase()+'Desc']}>{ratingType}</p>
        </div>
        <p className={styles.monthly}>{timeFrame}</p>
      </div>
    </div>
  );
};

export { MiniCard };
