import * as React from "react";
import styles from "./styles.module.scss";
import { UseFormRegister } from "react-hook-form";
import { EyeCloseIcon } from "assets";

interface InputProps {
  type?: string;
  accept?: string;
  dataTestID?: string;
  placeholder: string;
  className?: string;
  parentClassName?: string;
  name: string;
  id?: string;
  required?: boolean;
  minLength?: number;
  validatorMessage: string | undefined;
  label?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  iconFunction?: () => void;
  register: UseFormRegister<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: any;
  max?: number | string;
  readOnly?: boolean;
  value?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  showRequired?: boolean;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  type,
  dataTestID,
  placeholder,
  className,
  parentClassName,
  name,
  accept,
  id,
  required,
  minLength,
  validatorMessage,
  label,
  Icon,
  iconClassName,
  iconFunction,
  register,
  onChange,
  min,
  readOnly,
  value,
  onKeyDown,
  showRequired,
  disabled,
  onFocus,
  max,
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && (
        <label className={styles.label}>
          {label}
          {showRequired ? <span className={styles.req}>*</span> : ""}
        </label>
      )}
      {type === "password" ? (
        <>
          {!show ? (
            <EyeCloseIcon
              role="button"
              className={`${iconClassName} ${styles.icon}`}
              onClick={() => setShow(!show)}
            />
          ) : (
            <EyeCloseIcon
              className={`${iconClassName} ${styles.icon}`}
              onClick={() => setShow(!show)}
            />
          )}
        </>
      ) : (
        <>
          {Icon ? (
            <Icon
              role={iconFunction ? "button" : "none"}
              onClick={iconFunction && iconFunction}
              className={`${iconClassName} ${styles.icon}`}
              id="icon"
            />
          ) : (
            ""
          )}
        </>
      )}
      <input
        data-testid={dataTestID}
        className={`${styles.input} ${
          value !== "" ? styles.hasValue : ""
        } ${className}`}
        type={show ? "text" : type}
        placeholder={placeholder}
        accept={accept}
        id={id}
        {...register(name, {
          required: required,
          minLength: minLength,
          onChange: onChange,
          min: min,
          max: max,
        })}
        min={min}
        readOnly={readOnly}
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onFocus={onFocus}
      />

      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

const Textarea: React.FC<InputProps> = ({
  dataTestID,
  placeholder,
  className,
  parentClassName,
  name,
  id,
  required,
  minLength,
  validatorMessage,
  label,
  Icon,
  iconClassName,
  iconFunction,
  register,
  onChange,
  min,
  readOnly,
  value,
}) => {
  return (
    <div
      className={`${styles.inputWrapper} ${parentClassName} ${
        validatorMessage ? styles.error : ""
      }`}
    >
      {label && <label className={styles.label}>{label}</label>}
      {Icon ? (
        <Icon
          role={iconFunction ? "button" : "none"}
          onClick={iconFunction && iconFunction}
          className={`${iconClassName} ${styles.icon}`}
          id="icon"
        />
      ) : (
        ""
      )}
      <textarea
        data-testid={dataTestID}
        className={`${styles.textarea} ${
          value !== "" ? styles.hasValue : ""
        } ${className}`}
        placeholder={placeholder}
        id={id}
        {...register(name, {
          required: required,
          minLength: minLength,
          onChange: onChange,
          min: min,
        })}
        readOnly={readOnly}
        value={value}
      />

      {validatorMessage && (
        <small className={styles.message}>{validatorMessage}</small>
      )}
    </div>
  );
};

export { Input, Textarea };