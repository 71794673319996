import {
  candidatesAverageTimeService,
  candidatesCarouselService,
  candidatesService,
  deleteCandidateAssessmentService,
  resetCandidateAssessmentService,
  restartCandidateAssessmentService,
} from "api";
import {
  CandidatesTableItem,
  ConfirmationModal,
  Preloader,
  Toast,
} from "components";
import { CandidatesUI } from "features";
import { useApiRequest } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { optionType } from "types";

const Candidates = () => {
  // API REQUEST VARIABLES

  const {
    run: runCandidatesData,
    data: candidatesDataResponse,
    requestStatus: candidatesRequestStatus,
    error: candidatesError,
  } = useApiRequest({});
  const {
    run: runCarouselData,
    data: carouselDataResponse,
    requestStatus: carouselRequestStatus,
    error: carouselError,
  } = useApiRequest({});
  const {
    run: runAverageTimeData,
    data: averageTimeDataResponse,
    requestStatus: averageTimeRequestStatus,
    error: averageTimeError,
  } = useApiRequest({});
  const {
    run: runRestartData,
    data: restartDataResponse,
    requestStatus: restartRequestStatus,
    error: restartError,
  } = useApiRequest({});
  const {
    run: runResetData,
    data: resetDataResponse,
    requestStatus: resetRequestStatus,
    error: resetError,
  } = useApiRequest({});
  const {
    run: runDeleteData,
    data: deleteDataResponse,
    requestStatus: deleteRequestStatus,
    error: deleteError,
  } = useApiRequest({});

  // HOOK VARIABLES

  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [tableData, setTableData] = useState<CandidatesTableItem[]>([]);
  const totalUsers = tableData.length;
  const [totalPages, setTotalPages] = useState(1);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [averageTime, setAverageTime] = useState([]);
  const [showRestartAssessmentModal, setShowRestartAssessmentModal] =
    useState(false);
  const [showResetTimeModal, setShowResetTimeModal] = useState(false);
  const [currentId, setCurrentId] = useState<string>("");
  const [assessmentId, setAssessmentId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [progressText, setProgressText] = useState<string>("");
  const [retakers, setRetakers] = useState({
    count: "80",
    growth: "Growth"
  });
  const [checks, setChecks] = useState<boolean[]>(
    new Array(tableData.length).fill(false)
  );
  const getCheckedItemIds = () => {
    return tableData
      .filter((_, index) => checks[index])
      .map((item) => item.candidateID);
  };
  const checkedIds = getCheckedItemIds();
  const [carousel, setCarousel] = useState({
    overall_precision_score: {
      count: "",
      growth: "",
    },
    total_candidate_population: {
      count: "",
      growth: "",
    },
    total_completed_tests: {
      count: "",
      growth: "",
    },
    total_abandoned_tests: {
      count: "",
      growth: "",
    },
    total_avg_time_spent: {
      count: "",
      growth: "",
    },
  });
  const filterOptions: optionType[] = [
    {
      label: "Daily",
      value: "one_day",
    },
    {
      label: "Monthly",
      value: "one_month",
    },
    {
      label: "Yearly",
      value: "one_year",
    },
  ];
  const [period, setPeriod] = useState<optionType>(filterOptions[1]);
  const [timeFrame, setTimeFrame] = useState<"one_day" | "one_month" | "one_year"
  >("one_month");
  const [avgTimeFrame, setAvgTimeFrame] = useState<"one_day" | "one_month" | "one_year"
  >("one_month");

  // USE MEMO

  useMemo(() => {
    if (candidatesDataResponse?.status === 200) {
      const userData = candidatesDataResponse.data.results;
      setTotalCandidates(candidatesDataResponse?.data.count);
      setTotalPages(Math.ceil(candidatesDataResponse?.data.count / 10));

      const userList = userData.map((item: any) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        title: item.title,
        phoneNo: item.phone_number,
        assessmentID: item.assessment_id,
        candidateID: item.candidate_id,
        company: item.company,
        progress: item.status,
      }));
      setTableData(userList);
    } else if (candidatesError) {
      setToast({
        show: true,
        head: "Error",
        message: candidatesError.message,
      });
    }
  }, [candidatesDataResponse, candidatesError, totalCandidates]);
  useMemo(() => {
    if (restartDataResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "Candidate's Assessment Restarted",
      });
    } else if (restartError) {
      setToast({
        show: true,
        head: "Error",
        message: restartError?.message,
      });
    }
  }, [restartDataResponse, restartError]);
  useMemo(() => {
    if (resetDataResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "Candidate's Assessment Time Reset",
      });
    } else if (resetError) {
      setToast({
        show: true,
        head: "Error",
        message: resetError?.message,
      });
    }
  }, [resetDataResponse, resetError]);
  useMemo(() => {
    if (carouselDataResponse?.status === 200) {
      const carouselData = carouselDataResponse.data.data;
      setRetakers(carouselData.retakers)
      const data: any = {
        overall_precision_score: {
          count: Math.ceil(parseFloat(carouselData?.overall_precision_score.score)),
          growth: carouselData?.overall_precision_score.growth,
        },
        total_candidate_population: {
          count: carouselData?.total_candidate_population.count,
          growth: carouselData?.total_candidate_population.growth,
        },
        total_completed_tests: {
          count: carouselData?.total_completed_tests.count,
          growth: carouselData?.total_candidate_population.growth,
        },
        total_abandoned_tests: {
          count: carouselData?.total_abandoned_tests.count,
          growth: carouselData?.total_candidate_population.growth,
        },
        total_avg_time_spent: {
          count: carouselData?.total_avg_time_spent.startsWith("-")
            ? carouselData?.total_avg_time_spent.slice(1)
            : carouselData?.total_avg_time_spent,
          growth: "Growth",
        },
      };
      setCarousel(data);
    } else if (carouselError) {
      setToast({
        show: true,
        head: "Error",
        message: carouselError.message,
      });
    }
  }, [carouselDataResponse, carouselError]);
  useMemo(() => {
    if (averageTimeDataResponse?.status === 200) {
      const data = averageTimeDataResponse.data.data;
      const removeNegativeSign = (data: any) => {
        return data.map((item: any) => ({
          ...item,
          average_time_spent: item.average_time_spent.startsWith("-")
            ? item.average_time_spent.slice(1)
            : item.average_time_spent,
        }));
      };
      setAverageTime(removeNegativeSign(data));
    } else if (averageTimeError) {
      setToast({
        show: true,
        head: "Error",
        message: averageTimeError.message,
      });
    }
  }, [averageTimeDataResponse, averageTimeError]);
  useMemo(() => {
    if (deleteDataResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "Candidate's Assessment Deleted",
      });
    } else if (deleteError) {
      setToast({
        show: true,
        head: "Error",
        message: deleteError?.message,
      });
    }
  }, [deleteDataResponse, deleteError]);

  // USE EFFECT

  useEffect(() => {
    runCandidatesData(
      candidatesService({
        progress: progressText,
        search: searchTerm,
        page: currentPage,
      })
    );
  }, [runCandidatesData, progressText, searchTerm, currentPage]);
  useEffect(() => {
    runCarouselData(candidatesCarouselService({ time_frame: timeFrame }));
  }, [runCarouselData, timeFrame]);
  useEffect(() => {
    runAverageTimeData(candidatesAverageTimeService({ time_frame: avgTimeFrame}));
  }, [runAverageTimeData, avgTimeFrame]);

  // FUNCTIONS

  const handleFilter = (data: string) => {
    setProgressText(data);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };
  const sort = () => {
    setTableData([]);
  };
  const getID = (id: any) => {
    setAssessmentId(id);
  };
  const confirmCopyLink = () => {
    if (checkedIds.length > 0) {
      setChecks(new Array(tableData.length).fill(false));
    } else {
      setChecks(new Array(tableData.length).fill(false));
    }
  };
  const confirmResetTime = () => {
    if (checkedIds.length > 0) {
      setShowResetTimeModal(false);
      setChecks(new Array(tableData.length).fill(false));
      runResetData(
        resetCandidateAssessmentService({
          candidate_id: checkedIds[0],
          assessment_id: assessmentId,
        })
      );
      console.log(checkedIds[0], assessmentId);
    } else {
      setShowResetTimeModal(false);
      setChecks(new Array(tableData.length).fill(false));
    }
  };
  const confirmRestartAssessment = () => {
    if (checkedIds.length > 0) {
      setShowRestartAssessmentModal(false);
      setChecks(new Array(tableData.length).fill(false));

      runRestartData(
        restartCandidateAssessmentService({
          candidate_id: checkedIds[0],
          assessment_id: assessmentId,
        })
      );
    } else {
      setShowRestartAssessmentModal(false);
      setChecks(new Array(tableData.length).fill(false));
    }
  };
  const confirmDeletion = () => {
    if (checkedIds.length > 0) {
      setShowModal(false);
      setChecks(new Array(tableData.length).fill(false));
      runDeleteData(
        deleteCandidateAssessmentService(
          {
            candidate_id: checkedIds[0],
            assessment_id: assessmentId,
          },
          checkedIds[0]
        )
      );
    } else {
      setShowModal(false);
      setChecks(new Array(tableData.length).fill(false));
      runDeleteData(
        deleteCandidateAssessmentService(
          {
            candidate_id: currentId,
            assessment_id: assessmentId,
          },
          currentId
        )
      );
    }
  };
  const confirmReview = () => {
    if (checkedIds.length > 0) {
      setShowReviewModal(false);
      setChecks(new Array(tableData.length).fill(false));
    } else {
      setShowReviewModal(false);
      setChecks(new Array(tableData.length).fill(false));
    }
  };
  const resetTime = (id: string) => {
    setShowResetTimeModal(true);
    setCurrentId(id);
  };
  const restartAssessment = (id: string) => {
    setShowRestartAssessmentModal(true);
    setCurrentId(id);
  };
  const deleteCandidates = (candidateID: string, assessmentID: string) => {
    setShowModal(true);
    setCurrentId(candidateID);
  };
  const requestReview = (id: string, assessmentId: string) => {
    setShowReviewModal(true);
    setCurrentId(id);
    setAssessmentId(assessmentId);
  };
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  const handleFilterClick = (selectedOption: optionType) => {
    setPeriod(selectedOption);
    setTimeFrame(selectedOption.value);
  };
  const handleDuration = (time: "one_day" | "one_month" | "one_year") => {
    setAvgTimeFrame(time);
    console.log(time);
  }

  return (
    <>
      <ConfirmationModal
        confirm={() => {
          confirmReview();
          setShowReviewModal(false);
        }}
        show={showReviewModal}
        close={() => {
          setShowReviewModal(false);
        }}
        purpose="Are you sure you want to review this candidate's assessment?"
        header="Review assessment"
      />
      <ConfirmationModal
        confirm={() => {
          confirmResetTime();
          setShowResetTimeModal(false);
        }}
        show={showResetTimeModal}
        close={() => {
          setShowResetTimeModal(false);
        }}
        purpose="Are you sure you want to reset this candidate's time?"
        header="Reset time"
      />
      <ConfirmationModal
        confirm={() => {
          confirmRestartAssessment();
          setShowRestartAssessmentModal(false);
        }}
        show={showRestartAssessmentModal}
        close={() => {
          setShowRestartAssessmentModal(false);
        }}
        purpose="Are you sure you want to restart this candidate's assessment?"
        header="Restart assessment"
      />
      <ConfirmationModal
        confirm={() => {
          confirmDeletion();
          setShowModal(false);
        }}
        show={showModal}
        close={() => {
          setShowModal(false);
        }}
        purpose="Are you sure you want to delete this candidate's assessment?"
        header="Delete candidates"
      />
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader
        loading={
          averageTimeRequestStatus.isPending ||
          carouselRequestStatus.isPending ||
          restartRequestStatus.isPending ||
          resetRequestStatus.isPending ||
          deleteRequestStatus.isPending ||
          candidatesRequestStatus.isPending
        }
      />
      <CandidatesUI
        candidates={tableData}
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        pagination={{
          handleChange: handlePageChange,
          total: totalPages,
          current: currentPage,
          limit: 10
        }}
        sort={sort}
        checks={checks}
        setChecks={setChecks}
        resetTime={resetTime}
        restartAssessment={restartAssessment}
        deleteCandidates={deleteCandidates}
        copyLink={confirmCopyLink}
        requestReview={requestReview}
        handleDuration={handleDuration}
        progress={handleFilter}
        list={averageTime}
        assessmentId={getID}
        assessmentID={assessmentId}
        candidateID={checkedIds[0]}
        totalCandidates={totalCandidates.toString()}
        continuers=""
        retakers={retakers}
        filterOptions={filterOptions}
        handleFilterClick={handleFilterClick}
        period={period}
        carousel={carousel}
      />
    </>
  );
};

export { Candidates };
