import * as React from "react";
import { TableBody, TableBodyRow } from "..";
import styles from "./styles.module.scss";
import { DotIcon, profilePicture } from "assets";
import { CheckBox } from "components/checkBox";
import { SelectDropDown } from "components/selectDropDown";

export interface CandidatesTableItem {
  id: string;
  name: string;
  email: string;
  title: string;
  assessmentID: string;
  candidateID: string;
  company: string;
  phoneNo: string;
  progress: string;
}

interface TableBodyProps {
  tableBodyItems: CandidatesTableItem[];
  tableBodyItemClassName?: string;
  tableBodyRowClassName?: string;
  tableBodyStatus?: string;
  statusItem?: string;
  statusColor?: string;
  checks: boolean[];
  handleCheck: ({ index, value, assessmentID }: { index: number; value: boolean, assessmentID : string }) => void;
  shownHeaders: { title: string; Icon: React.FC }[];
  handleDelete: (id: string, assessmentID: string) => void;
  handleRequestReview: (id: string, assessmentID: string) => void;
}

const CandidatesTable: React.FC<TableBodyProps> = ({
  tableBodyItems,
  tableBodyItemClassName,
  tableBodyRowClassName,
  statusItem,
  statusColor,
  checks,
  handleCheck,
  shownHeaders,
  handleRequestReview,
  handleDelete,
}) => {
  const items = (candidateID: string, assessmentID: string) => [
    {
      name: "Request review",
      action: () => {
        handleRequestReview(candidateID, assessmentID);
      },
    },
    {
      name: "Delete candidates",
      action: () => {
        handleDelete(candidateID, assessmentID);
      },
    },
  ];
  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => (
          <TableBodyRow
            key={`body ${index}`}
            customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
          >
            {shownHeaders.map((header, headerIndex) => (
              <div
                key={headerIndex}
                className={`${tableBodyItemClassName} ${styles.box}`}
                onClick={()=>{console.log(item.candidateID)}}
              >
                {header.title === "Name" && (
                  <span className={`${styles.nameSection}`}>
                      <CheckBox
                        inputClassName={styles.check}
                        id={""}
                        checked={checks[index]}
                        onChange={() =>
                          handleCheck({ index, value: !checks[index], assessmentID: item.assessmentID})
                        }
                      />
                      <img src={profilePicture} alt="" />
                      <span className={styles.nameFlex}>
                        <span
                          className={` ${styles.businessName}`}
                        >
                          {item.name}
                        </span>
                        <span
                          className={` ${styles.businessEmail}`}
                        >
                          {item.email}
                        </span>
                      </span>
                  </span>
                )}

                {header.title === "Assessment title" && (
                  <span className={`${tableBodyItemClassName} ${styles.namee}`}>
                    <span className={styles.scrollable}>{item.title}</span>
                  </span>
                )}
                {header.title === "Assessment ID" && (
                  <span className={tableBodyItemClassName}>
                    {item.assessmentID}
                  </span>
                )}
                {header.title === "Company" && (
                  <span className={`${tableBodyItemClassName} ${styles.company}`}>
                    {item.company}
                  </span>
                )}
                {header.title === "Phone no." && (
                  <span className={tableBodyItemClassName}>{item.phoneNo}</span>
                )}
                {header.title === "Progress" && (
                  <span
                    className={`${tableBodyItemClassName} ${styles.statusSection}`}
                  >
                    <p
                      className={`
                      ${statusItem} 
                      ${statusColor}
                      ${styles.status}
                      ${styles["text" + item.progress.toLowerCase()]}
                      `}
                    >
                      <DotIcon
                        className={`
                          ${styles.dot} 
                          ${styles["dot" + item.progress.toLowerCase()]
                        }`}
                      />
                      <span className={styles.progress                                                                                                                          }>{item.progress}</span>
                    </p>
                  </span>
                )}
                {header.title === "Dots" && (
                  <span className={`${tableBodyItemClassName} ${styles.dots}`}>
                    <SelectDropDown
                      className={styles.SelectDropDown}
                      items={items(item.candidateID, item.assessmentID)}
                    />
                  </span>
                )}
              </div>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  );
};

export { CandidatesTable };
