import { reviewService } from "api";
import { initReviewsFilterData, Preloader, ReviewsFilterData, ReviewsTableItem, Toast } from "components";
import { ReviewsUI } from "features";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";

const Reviews = () => {
  const { id, id2 } = useParams();
  const {
    run: runReviewData,
    data: reviewDataResponse,
    requestStatus: reviewRequestStatus,
    error: reviewError,
  } = useApiRequest({});
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [assessmentTitle, setAssessmentTitle] = useState("")
  const [reviewSearchTerm, setReviewSearchTerm] = useState("");
  const [reviewsPage, setReviewsPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReviews, setTotalReviews] = useState(0);
  const [filter, setFilter] = useState<ReviewsFilterData>(initReviewsFilterData);
  const [reviewTable, setReviewTable] = useState<ReviewsTableItem[]>([]);
  useMemo(() => {
    if (reviewDataResponse?.status === 200) {
      const reviewData: any[] = reviewDataResponse?.data.results;
      console.log(reviewData)
      setAssessmentTitle(reviewData[0]?.assessment_title)
      const reviewList = reviewData.map((item: any) => ({
        id: item.candidate_id,
        candidateName: item.full_name,
        comment: item.feedback,
        rating: item.rating,
      }));
      setReviewTable(reviewList);
      setTotalReviews(reviewList.length);
      setTotalPages(Math.ceil(totalReviews / 9));
    } else if (reviewError) {
      setToast({
        show: true,
        head: "Error",
        message: reviewError.message,
      });
    }
  }, [reviewDataResponse, reviewError]);

  const handleReviewPageChange = (page: number) => {
    setReviewsPage(page);
  };
  const handleReviewSearch = (searchTerm: string) => {
    setReviewSearchTerm(searchTerm);
    setReviewsPage(1);
  };
  const handleReviewFilter = (data: ReviewsFilterData) => {
    setFilter(data)
    setReviewsPage(1);
  };
  useEffect(() => {
    runReviewData(reviewService({
      id: id,
      id2: id2,
      search: reviewSearchTerm,
      rating: filter.rating?.value,
    }));
  }, [id, runReviewData, filter, reviewSearchTerm, reviewsPage]);
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  const reviewSort = () => {
    setReviewTable([]);
  };
  const handleView = (id: any, id2: any, id3: any) => {
    return navigate(Routes.candidateReview(id, id2, id3));
  };
  return (
    <div>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={reviewRequestStatus.isPending} />
      <ReviewsUI
        assessmentTitle= {assessmentTitle}
        reviewsTable={reviewTable}
        reviewSearchTerm={reviewSearchTerm}
        handleReviewSearch={handleReviewSearch}
        handleReviewFilter={handleReviewFilter}
        handleView={handleView}
        reviewPagination={{
          handleChange: handleReviewPageChange,
          total: totalPages,
          current: reviewsPage,
          limit: 8
        }}
        reviewSort={reviewSort}
      />
    </div>
  );
};

export { Reviews };
