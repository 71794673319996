import {
  ArrowIcon,
  ArrowLeftIcon,
  CloseIcon,
  EmptyTable,
  EyeIcon,
  EyeSlashIcon,
  SelectIcon,
  SendIcon,
  TableSelectIcon,
  TrashIcon,
  UsersIcon,
} from "assets";
import {
  MiniCard,
  BreadCrumbs,
  Pagination,
  Search,
  Table,
  EmployersTable,
  EmployerTableItem,
  EmployersFilter,
  Button,
  EmployersFilterData,
  FilterTabs,
} from "components";
import { Routes } from "router";
import styles from "./styles.module.scss";
import { useRef, useState } from "react";
import { useClickOutside } from "helpers";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { optionType } from "types";

interface EmployerProps {
  employers: EmployerTableItem[];
  searchTerm: string;
  handleSearch: (data: string) => void;
  handleFilter: (data: EmployersFilterData) => void;
  handleView: (id: number) => void;
  pagination: {
    handleChange: (page: number) => void;
    total: number;
    current: number;
    limit: number;
  };
  sort: () => void;
  checks: boolean[];
  setChecks: (check: boolean[]) => void;
  deactivateEmployers: (id?: string) => void;
  requestReview: (id?: string) => void;
  carousel: {
    employerAccount: {
      count: string;
      growth: string;
    };
    activeEmployers: {
      count: string;
      growth: string;
    };
    dormantEmployers: {
      count: string;
      growth: string;
    };
    createdRoles: {
      count: string;
      growth: string;
    };
    assessedRoles: {
      count: string;
      growth: string;
    };
    closedRoles: {
      count: string;
      growth: string;
    };
    rolesAssessed: {
      count: string;
      growth: string;
    };
    completedTests: {
      count: string;
      growth: string;
    };
  };
  filterOptions: any[];
  handleFilterClick: (data: optionType) => void;
  period: optionType;
}

export const EmptyComponent = () => null;

const EmployersUI: React.FC<EmployerProps> = ({
  employers,
  searchTerm,
  handleSearch,
  handleFilter,
  handleView,
  pagination,
  sort,
  checks,
  setChecks,
  deactivateEmployers,
  requestReview,
  filterOptions,
  handleFilterClick,
  period,
  carousel
}) => {
  const tableHeaderTitles = [
    {
      index: 1,
      title: "Business name",
      Icon: EmptyComponent,
    },
    {
      index: 2,
      title: "Contact person",
      Icon: EmptyComponent,
    },
    {
      index: 3,
      title: "Phone no.",
      Icon: EmptyComponent,
    },
    {
      index: 4,
      title: "Plan type",
      Icon: EmptyComponent,
    },
    {
      index: 5,
      title: "No. of candidates",
      Icon: ArrowIcon,
    },
    {
      index: 6,
      title: "Status",
      Icon: EmptyComponent,
    },
    {
      index: 7,
      title: "Dots",
      Icon: EmptyComponent,
    },
  ];
  const CardData = [
    {
      id: 1,
      header: "Employer Accounts",
      users: carousel.employerAccount.count,
      ratingType: carousel.employerAccount.growth,
      timeFrame: period.label
    },
    {
      id: 2,
      header: "Active Employers",
      users: carousel.activeEmployers.count,
      ratingType: carousel.activeEmployers.growth,
      timeFrame: period.label
    },
    {
      id: 3,
      header: "Dormant Employers",
      users: carousel.dormantEmployers.count,
      ratingType: carousel.dormantEmployers.growth,
      timeFrame: period.label
    },
    {
      id: 4,
      header: "Created Roles",
      users: carousel.createdRoles.count,
      ratingType: carousel.createdRoles.growth,
      timeFrame: period.label
    },
    {
      id: 5,
      header: "Assessed Roles",
      users: carousel.assessedRoles.count,
      ratingType: carousel.assessedRoles.growth,
      timeFrame: period.label
    },
    {
      id: 6,
      header: "Closed Roles",
      users: carousel.closedRoles.count,
      ratingType: carousel.closedRoles.growth,
      timeFrame: period.label
    },
    {
      id: 7,
      header: "Roles Assessed",
      users: carousel.rolesAssessed.count,
      ratingType: carousel.rolesAssessed.growth,
      timeFrame: period.label
    },
    {
      id: 8,
      header: "Completed tests",
      users: carousel.completedTests.count,
      ratingType: carousel.completedTests.growth,
      timeFrame: period.label
    },
    ];
  const checkTrue = checks.filter((value) => value === true);
  const [show, setShow] = useState(false);

  const [headerVisibility, setHeaderVisibility] = useState(
    tableHeaderTitles.map(() => true)
  );
  const shouldHideTable = headerVisibility
    .slice(0, -1)
    .every((visible) => !visible);
  const toggleVisibility = (index: number) => {
    setHeaderVisibility((prev) =>
      prev.map((visible, idx) => (idx === index ? !visible : visible))
    );
  };
  const shownHeaders = tableHeaderTitles.filter(
    (_, index) => headerVisibility[index]
  );
  const hiddenHeaders = tableHeaderTitles
    .slice(0, -1)
    .filter((_, index) => !headerVisibility[index]);

  const dropdownRef = useRef(null);
  const containerRef = useRef(null);
  useClickOutside(dropdownRef, () => setShow(false));
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: -scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: scrollRef.current.clientWidth,
      behavior: "smooth",
    });
  };

  const [droppedItems, setDroppedItems] = useState<any[]>([]);
  const handleDrop = (item: any) => {
    setDroppedItems((prevItems) => [...prevItems, item]);
    toggleVisibility(item.id);
  };

  const handleCheck = ({ index, value }: { index: number; value: boolean }) => {
    const newChecks = [...checks];
    newChecks[index] = value;
    setChecks(newChecks);
  };

  return (
    <>
      <div className={styles.employers}>
        <BreadCrumbs
          className={styles.navItem}
          items={[
            {
              Icon: UsersIcon,
              label: "Users",
              link: "",
            },
            {
              Icon: EmptyComponent,
              label: "Employers",
              link: Routes.employers,
            },
          ]}
        />
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.head}>Users</h3>
            <p className={styles.subHead}>
              View and manage details of all registered employers on precision.
            </p>
          </div>
          <div className={styles.right}>
            <div className={styles.leftArrow}>
              <ArrowLeftIcon onClick={scrollLeft} />
            </div>
            <div className={styles.rightArrow} onClick={scrollRight}>
              <ArrowLeftIcon />
            </div>
          </div>
        </div>
        <FilterTabs
          filters={filterOptions}
          onChange={handleFilterClick}
          value={period}
          className={styles.filterTabs}
        />
        <div className={styles.cards}>
          <div className={styles.cardScroll} ref={scrollRef}>
            {CardData.map((item, index) => (
              <MiniCard
                openChart={() => {}}
                key={index}
                header={item.header}
                users={item.users}
                ratingType={item.ratingType}
                className={styles.card}
                timeFrame={item.timeFrame}
              />
            ))}
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableBar}>
            <h3 className={styles.head}>All Employers</h3>
            <div className={styles.tableActions}>
              <div className={styles.filterScroll}>
                <EmployersFilter
                  submit={handleFilter}
                  className={styles.filter}
                />
              </div>

              <Search
                className={styles.search}
                value={searchTerm}
                placeholder="Search in list..."
                handleChange={(e) => {
                  handleSearch(e);
                }}
              />
              <div className={styles.selectContainer} ref={dropdownRef}>
                <div
                  className={`${show ? styles.selectIcon : styles.prev}`}
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <SelectIcon className={`${styles.select}`} />
                </div>
                {show && (
                  <DndProvider backend={HTML5Backend}>
                    <div className={styles.tableEdit}>
                      <div className={styles.headerr}>
                        <div className={styles.titles}>
                          <div className={styles.top}>View Option</div>
                          <p className={styles.sub}>
                            Max of seven ({tableHeaderTitles.length - 1}) items{" "}
                          </p>
                        </div>
                        <div
                          className={styles.close}
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <CloseIcon />
                        </div>
                      </div>
                      <DropZone
                        className={`${styles.bodyHead} ${styles.hiddenHead}`}
                        onDrop={handleDrop}
                        title="Shown"
                      />
                      <div className={styles.bodyContent} ref={containerRef}>
                        {shownHeaders.map((item, shownIndex) => {
                          const originalIndex = tableHeaderTitles.indexOf(item);
                          return (
                            <DragItem
                              title={item.title}
                              originalIndex={originalIndex}
                              toggleVisibility={toggleVisibility}
                              key={originalIndex}
                              isHidden={false}
                            />
                          );
                        })}
                      </div>
                      <DropZone
                        className={`${styles.bodyHead} ${styles.hiddenHead}`}
                        onDrop={handleDrop}
                        title="Hidden"
                      />
                      <div
                        className={`${styles.bodyContent} ${styles.hidden}`}
                        style={{
                          padding: "10px",
                        }}
                      >
                        {hiddenHeaders.map((item, hiddenIndex) => {
                          const originalIndex = tableHeaderTitles.indexOf(item);
                          return (
                            <DragItem
                              title={item.title}
                              originalIndex={originalIndex}
                              toggleVisibility={toggleVisibility}
                              key={originalIndex}
                              isHidden={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </DndProvider>
                )}
              </div>
            </div>
          </div>
          {shouldHideTable ? (
            <div className={styles.emptyTable}>
              <EmptyTable />
            </div>
          ) : (
            <Table
              tableHeaderTitles={shownHeaders}
              tableBody={
                <EmployersTable
                  tableBodyItems={employers}
                  tableBodyItemClassName={styles.tableItem}
                  tableBodyRowClassName={styles.tableBodyItem}
                  tableBodyStatus={styles.tableBodyStatus}
                  shownHeaders={shownHeaders}
                  statusItem={styles.statusItem}
                  checks={checks}
                  handleCheck={handleCheck}
                  view={handleView}
                  handleDeactivate={deactivateEmployers}
                  handleRequestReview={requestReview}
                />
              }
              customTableClasses={{
                tableContainerClassName: styles.tableWrap,
                tableHeaderClassName: styles.tableHeader,
                tableHeaderItemClassName: styles.tableHeaderItem,
                statusClass: styles.statusClass,
              }}
              headerVisibility={headerVisibility}
              sort={sort}
            />
          )}
          <Pagination
            current={pagination.current}
            total={pagination.total}
            handleChange={pagination.handleChange}
            limit={pagination.limit}
            parentClassName={styles.pagin}
            classP={styles.paginationBtn}
            paginText={styles.paginText}
          />
        </div>
        {checkTrue.length !== 0 && (
          <div className={styles.employerSelected}>
            <div className={styles.amount}>
              {checkTrue.length} employer{checkTrue.length > 1 && "s"} selected
            </div>
            <div className={styles.buttons}>
              <Button
                type="fill"
                onClick={() => {
                  requestReview();
                }}
                className={styles.requestBtn}
              >
                <SendIcon />
                Request review
              </Button>
              <Button
                type="transparent"
                onClick={() => {
                  deactivateEmployers();
                }}
                className={styles.deleteBtn}
              >
                <TrashIcon />
                Deactivate employers
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const DragItem = ({
  title,
  originalIndex,
  toggleVisibility,
  isHidden,
}: any) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "item",
    item: { id: originalIndex, name: title },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`${styles.row} ${title === "Dots" && styles.none}`}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
      key={originalIndex}
    >
      <div className={styles.name}>
        <div className={`${styles.selects} ${styles.dragHandle}`}>
          <TableSelectIcon />
        </div>
        <p className={styles.headerName}>{title}</p>
      </div>
      {isHidden ? (
        <EyeSlashIcon
          onClick={() => toggleVisibility(originalIndex)}
          className={styles.eye}
        />
      ) : (
        <EyeIcon
          onClick={() => toggleVisibility(originalIndex)}
          className={styles.eye}
        />
      )}
    </div>
  );
};

const DropZone = ({ onDrop, title }: any) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "item",
    drop: (item) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`${styles.bodyHead} ${
        title === "Hidden" && styles.hiddenHead
      } `}
    >
      <p className={styles.show}>{title}</p>
    </div>
  );
};

export { EmployersUI };
