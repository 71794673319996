import { CandidatesIcon, FilterIconOutline } from "assets";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  EmployersFilterData,
  EmployersFilterModal,
  schema,
  ActiveFilters,
  initFilterData
} from "components";

interface EmployersFilterProps {
  submit: (data: EmployersFilterData) => void;
  className?: string;
}
const EmployersFilter: React.FC<EmployersFilterProps> = ({
  className = "",
  submit,
}) => {
  const form = useForm<EmployersFilterData>({
    resolver: yupResolver<EmployersFilterData>(schema),
    defaultValues: initFilterData,
  });
  const [showModal, setModal] = useState(false);
  const handleFilterSubmit = (data: EmployersFilterData) => {
    submit(data);
    setModal(false);
  };
  const resetAndSubmit = (key: keyof EmployersFilterData) => {
    form.setValue(key, undefined);
    handleFilterSubmit(form.watch());
  };
  const filter = [
    {
      icon: "",
      value: !form.getValues(`regular`) ? "" : 'Regular',
      reset: () => resetAndSubmit('regular'),
    },
    {
      icon: "",
      value: !form.getValues(`pro`) ? "" : `Pro`,
      reset: () => resetAndSubmit('pro'),
    },
    {
      icon: "",
      value: !form.getValues(`payg`) ? "" : `Payg`,
      reset: () => resetAndSubmit('payg'),
    },
    {
      icon: "",
      value: !form.getValues(`freeTrial`) ? "" : `Free Trial`,
      reset: () => resetAndSubmit('freeTrial'),
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`candidatesMin`) || !form.getValues(`candidatesMax`) ? "" : `${form.getValues(`candidatesMin`)} - ${form.getValues(
        `candidatesMax`
      )}`,
      reset: () => {
        resetAndSubmit('candidatesMin');
        resetAndSubmit('candidatesMax');
      },
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`assessedMin`) || !form.getValues(`assessedMax`) ? "" : `${form.getValues(`assessedMin`)} - ${form.getValues(
        `assessedMax`
      )}`,
      reset: () => {
        resetAndSubmit('assessedMin');
        resetAndSubmit('assessedMax');
      },
    },
    {
      icon: <CandidatesIcon />,
      value: !form.getValues(`createdMin`) || !form.getValues(`createdMax`) ? "" : `${form.getValues(`createdMin`)} - ${form.getValues(
        `createdMax`
      )}`,
      reset: () => {
        resetAndSubmit('createdMin');
        resetAndSubmit('createdMax');
      },
    },
    {
      icon: "",
      value: !form.getValues(`active`) ? "" : `Active`,
      reset: () => resetAndSubmit('active'),
    },
    {
      icon: "",
      value: !form.getValues(`paused`) ? "" : `Paused`,
      reset: () => resetAndSubmit('paused'),
    },
    {
      icon: "",
      value: !form.getValues(`terminated`) ? "" : `Terminated`,
      reset: () => resetAndSubmit('terminated'),
    },
  ].filter(
    (item) => item.value !== ""
  );
  useEffect(() => {
    form.watch();
  }, [form]);
  return (
    <>
      {filter.length === 0 ? (
        <div
          onClick={() => setModal(true)}
          className={`${styles.filter} ${className}`}
        >
          <FilterIconOutline role="button" className={styles.filterIcon} />
          <p className={styles.text}>Filter</p>
        </div>
      ) : (
        <ActiveFilters
          className={className}
          click={() => {
            setModal(true);
          }}
          filterData={filter}
        />
      )}
      <EmployersFilterModal
        show={showModal}
        close={() => setModal(false)}
        submit={handleFilterSubmit}
        form={form}
      />
    </>
  );
};

export { EmployersFilter };
