import { employerProfileService } from "api";
import { Preloader, Toast } from "components";
import { CompanyFormData, ContactFormData, ProfileUI } from "features";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { id } = useParams();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const [companyLogo, setCompanyLogo] = useState("");
  const [title, setTitle] = useState("");
  const [contactValues, setContactValues] = useState<ContactFormData>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    job: "",
  });
  const [companyValues, setCompanyValues] = useState<CompanyFormData>({
    companyName: "",
    industry: "",
    hiringFrequency: { label: "", value: "" },
    employeeNo: { label: "", value: "" },
  });

  const {
    run: runProfileData,
    data: profileDataResponse,
    requestStatus: profileRequestStatus,
    error: profileError,
  } = useApiRequest({});

  useMemo(() => {
    if (profileDataResponse?.status === 200) {
      const profileData = profileDataResponse.data;
      setCompanyLogo(profileData.company_logo);
      setTitle(profileData.name)
      const companyData = {
        companyName: profileData.name,
        industry: profileData.industry,
        hiringFrequency: {
          label: profileData.hiring_frequency.replace(" hire annually", ""),
          value: profileData.hiring_frequency.replace(" hire annually", ""),
        },
        employeeNo: {
          label: profileData.no_of_employees,
          value: profileData.no_of_employees,
        },
      };

      const contactData = {
        firstname: profileData.first_name,
        lastname: profileData.last_name,
        phone: '0'+profileData.phone_number,
        email: profileData.email,
        job: profileData.role,
      }

      setContactValues(contactData);
      setCompanyValues(companyData);
    } else if (profileError) {
      setToast({
        show: true,
        head: "Error",
        message: profileError.message,
      });
    }
  }, [profileDataResponse, profileError]);

  useEffect(() => {
    runProfileData(employerProfileService(id));
  }, [id, runProfileData]);

  const contactSubmit = (data: ContactFormData) => {};
  const companySubmit = (data: CompanyFormData) => {};
  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };

  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={profileRequestStatus.isPending} />
      <ProfileUI
        contactSubmit={contactSubmit}
        companySubmit={companySubmit}
        contactValues={contactValues}
        companyValues={companyValues}
        companyLogo={companyLogo}
        title={title}
      />
    </>
  );
};

export { Profile };
