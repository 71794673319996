/*
=================================
USERS URLS
=================================
*/
/**
 * FetchEmployers URL
 *
 * @returns url string
 *
 */

export const employersURL = () => `/admin/all-employers`;

/**
 * employersCarousel URL
 *
 * @returns url string
 *
 */

export const employersCarouselURL = () => `/admin/employers-carousel/`;

/**
 * profile URL
 *
 * @returns url string
 *
 */

export const profileURL = (id: any) => `/admin/all-employers/${id}/`;

/**
 * assessments URL
 *
 * @returns url string
 *
 */

export const assessmentsURL = (id?: string) => `/admin/employers-all-assessments/${id}`;

/**
 * assessmentModal URL
 *
 * @returns url string
 *
 */

export const assessmentModalURL = (id?: string, id2?: string) => `/admin/employers-all-assessments/${id}/${id2}`;

/**
 * assessmentsCarousel URL
 *
 * @returns url string
 *
 */

export const assessmentsCarouselURL = (id: any) => `/admin/employer-assessment-carousel/${id}/`;

/**
 * Assessment Chart URL
 *
 * @returns url string
 *
 */

export const assessmentChartURL = (id?: string) => `/admin/frequently-created-roles/${id}/`

/**
 * frequentlyAccessedRoles URL
 *
 * @returns url string
 *
 */

export const frequentlyAccessedRolesURL = (id?: string) => `/admin/frequently-accessed-roles/${id}/`;

/**
 * reports URL
 *
 * @returns url string
 *
 */

export const reportsURL = (id?: string) => `/admin/assessments/${id}/`;

/**
 * reportsCarousel URL
 *
 * @returns url string
 *
 */

export const reportsCarouselURL = (id?: string) => `/admin/reports-dashboard/${id}/`;

/**
 * reportsAssessed URL
 *
 * @returns url string
 *
 */


/**
 * Report Chart URL
 *
 * @returns url string
 *
 */

export const reportChartURL = (id?: string) => `/admin/frequently-assessed-roles/${id}/`;

export const reportsAssessedURL = (id?: string) => `/admin/assessments/${id}/candidates/`

/**
 * reportsConsider URL
 *
 * @returns url string
 *
 */

export const reportsConsiderURL = (id?: string) => `/admin/assessments/${id}/candidates/`;

/**
 * reportsInterview URL
 *
 * @returns url string
 *
 */

export const reportsInterviewURL = (id?: string) =>`/admin/assessments/${id}/candidates/`;

/**
 * reportsRejected URL
 *
 * @returns url string
 *
 */

export const reportsRejectedURL = (id?: string) =>`/admin/assessments/${id}/candidates/`
;

/**
 * candidateReport URL
 *
 * @returns url string
 *
 */

export const candidateReportURL = (id?: string) => `/admin/assessments/${id}/candidates/`;

/**
 * candidates URL
 *
 * @returns url string
 *
 */

export const candidatesURL = () => `/admin/candidate-dashboard/`;

/**
 * candidates URL
 *
 * @returns url string
 *
 */

export const candidatesCarouselURL = () => `/admin/candidate-dashboard/dashboard/`;

/**
 * reviews URL
 *
 * @returns url string
 *
 */

export const reviewsURL = (id?: string, id2?: string) => `/admin/owners/${id}/assessments/${id2}/reviews/`;

/**
 * Single Review URL
 *
 * @returns url string
 *
 */

export const singleReviewURL = (id?: string, id2?: string, id3?: string) => `/admin/owners/${id}/assessments/${id2}/reviews/${id3}`;

/**
 * candidatesAverageTime URL
 *
 * @returns url string
 *
 */

export const candidatesAverageTimeURL = () => `/admin/candidate-dashboard/average_time_per_assessment/`;

/**
 * candidatesResetTime URL
 *
 * @returns url string
 *
 */

export const candidatesResetTimeURL = () => `/admin/candidate-dashboard/reset_time/`;

/**
 * candidatesRestart URL
 *
 * @returns url string
 *
 */

export const candidatesRestartURL = () => `/admin/candidate-dashboard/restart_assessment/`;

/**
 * deleteCandidateAssessment URL
 *
 * @returns url string
 *
 */

export const deleteCandidateAssessmentURL = (id?: string) => `/admin/candidate-dashboard/${id}/`;