import {
  AccountIcon,
  HamburgerIcon,
  IntegrationsIcon,
  LogoWithText,
  LogoutIcon,
  OverviewIcon,
  PaymentIcon,
  ReviewsIcon,
  SupportIcon,
  TemplateIcon,
  UsersIcon,
} from "assets";
import styles from "./styles.module.scss";
import { useMemo, useRef, useState } from "react";
import { SidebarItem, SidebarType, admin, menu } from "./sidebarItem";
import { Routes } from "router";
import { Preloader, Toast } from "components";
import { useApiRequest } from "hooks";
import { logoutService } from "api";
import { useNavigate } from "react-router-dom";

export interface LayoutProps {
  active: menu | admin;
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ active, children }) => {
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, head: "", message: "" });
  const { run: runLogout, data: logoutResponse, error, requestStatus } = useApiRequest({});
  const logout = () => {
    localStorage.removeItem("precisionAdminAccess");

    runLogout(logoutService());
  };

  useMemo(() => {
    if (logoutResponse?.status === 200) {
      setToast({
        show: true,
        head: "Success",
        message: "Logout successful!",
      });
      setTimeout(() => {
        navigate(Routes.home);
      }, 2000);
    } else if (error) {
      setToast({
        show: true,
        head: "Error",
        message: error.message,
      });
    }
  }, [logoutResponse, error, navigate]);

  const handleClose = () => {
    setToast({ show: false, head: "", message: "" });
  };
  const EmptyComponent = () => null;
  const SidebarItems: SidebarType[] = [
    {
      active,
      state: "MENU",
      url: "",
      type: "header",
      Icon: EmptyComponent,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      state: "Overview",
      url: Routes.overview,
      type: "link",
      Icon: OverviewIcon,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      state: "Templates",
      url: Routes.overview,
      type: "link",
      Icon: TemplateIcon,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      state: "Users",
      url: "",
      type: "link",
      Icon: UsersIcon,
      action: () => false,
      categories: [
        {
          state: "Employers",
          url: Routes.employers,
          active,
          action: () => setShowMenu(false),
        },
        {
          state: "Candidates",
          url: Routes.candidates,
          active,
          action: () => setShowMenu(false),
        },
      ],
    },
    {
      active,
      state: "Payment Information",
      url: "",
      type: "link",
      Icon: PaymentIcon,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      state: "Reviews",
      url: "",
      type: "link",
      Icon: ReviewsIcon,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      state: "ADMINISTRATION",
      url: "",
      type: "header",
      Icon: EmptyComponent,
      action: () => setShowMenu(false),
      categories: [],
    },
    {
      active,
      url: Routes.overview,
      state: "Integrations",
      type: "link",
      action: () => setShowMenu(true),
      categories: [],
      Icon: IntegrationsIcon,
    },
    {
      active,
      url: Routes.overview,
      state: "Support",
      type: "link",
      action: () => setShowMenu(true),
      categories: [],
      Icon: SupportIcon,
    },
    {
      active,
      url: Routes.overview,
      state: "Account",
      type: "link",
      action: () => setShowMenu(true),
      categories: [],
      Icon: AccountIcon,
    },
    {
      active,
      url: "",
      state: "Sign Out",
      type: "link",
      action: () => {
        logout();
      },
      categories: [],
      Icon: LogoutIcon,
    },
  ];

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const name = localStorage.getItem('userName');
  const email = localStorage.getItem('userEmail');
  const displayPhoto = localStorage.getItem('displayPhoto');
  return (
    <>
      <Toast
        onClose={handleClose}
        loading={toast.show}
        head={toast.head}
        message={toast.message}
      />
      <Preloader loading={requestStatus.isPending} />
      <main className={styles.layout}>
        <div className={`${styles.navBar} ${styles.navNone}`}>
          <LogoWithText className={styles.logo} />
          <HamburgerIcon
            onClick={() => setShowMenu(!showMenu)}
            className={`${showMenu ? styles.rotate : styles.menuIcon}`}
          />
        </div>
        <nav
          className={`${styles.sideBar} ${
            showMenu ? styles.show : styles.none
          }`}
        >
          <ul ref={menuRef} className={styles.sidebarList}>
            <section className={styles.user}>
              <img src={displayPhoto || ""} alt="" className={styles.pfpImage} />
              <div className={styles.details}>
                <h3 className={styles.head}>{name === "null" ? "Not set" : name}</h3>
                <p className={styles.email}>{email}</p>
              </div>
            </section>
            <div className={styles.items}>
              <div className={styles.top}>
                {SidebarItems.slice(0, 6).map((item, index) => (
                  <SidebarItem {...item} key={index} />
                ))}
              </div>
              <div className={styles.bottom}>
                {SidebarItems.slice(6).map((item, index) => (
                  <SidebarItem {...item} key={index + 5} />
                ))}
              </div>
            </div>
          </ul>
        </nav>
        <div className={styles.content}>{children}</div>
      </main>
    </>
  );
};
export { Layout };
