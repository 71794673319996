/*
=================================
USERS SERVICES
=================================
*/

import { deleteRequest, getRequest, patchRequest } from "api/requestProcessor";
import {
  assessmentChartURL,
  assessmentModalURL,
  assessmentsCarouselURL,
  assessmentsURL,
  candidateReportURL,
  candidatesAverageTimeURL,
  candidatesCarouselURL,
  candidatesResetTimeURL,
  candidatesRestartURL,
  candidatesURL,
  deleteCandidateAssessmentURL,
  employersCarouselURL,
  employersURL,
  profileURL,
  reportsAssessedURL,
  reportsCarouselURL,
  reportsConsiderURL,
  reportsInterviewURL,
  reportsRejectedURL,
  reportsURL,
  reviewsURL,
  singleReviewURL,
} from "api/urls";

/**
 * fetchEmployers service
 * @returns axios promise
 */

export interface EmployersData {
  status?: "active" | "inactive" | string | null;
  search?: string;
  page?: number;
  maxCandidates?: string | null;
  minCandidates?: string | null;
  planType?: string | null;
  noOfRolesAccessed?: string | null;
  noOfCreatedRoles?: string | null;
}

export const employerService = (data: EmployersData) => {
  const request = {
    url: employersURL(),
    config: {
      params: {
        status: data.status ? data.status.toLowerCase() : undefined,
        search: data.search || "",
        page: data.page || "",
        maxCandidates: data.maxCandidates ? data.maxCandidates : undefined,
        minCandidates: data.minCandidates ? data.minCandidates : undefined,
        plan_type: data.planType ? data.planType : undefined,
        noOfRolesAccessed: data.noOfRolesAccessed
          ? data.noOfRolesAccessed
          : undefined,
        noOfCreatedRoles: data.noOfCreatedRoles
          ? data.noOfCreatedRoles
          : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * employerCarousel service
 * @returns axios promise
 */

interface EmployerCarouselData {
  time_frame: "one_day" | "one_month" | "one_year";
}

export const employerCarouselService = (data: EmployerCarouselData) => {
  const request = {
    url: employersCarouselURL(),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * employerProfile service
 * @returns axios promise
 */

export const employerProfileService = (id: any) => {
  const request = {
    url: profileURL(id),
  };
  return getRequest(request);
};

/**
 * employerAssessment service
 * @returns axios promise
 */

export interface EmployerAssessmentData {
  id?: string;
  status: "published" | "drafts";
  search?: string;
  page?: number;
  assessment_status?:
    | "active"
    | "paused"
    | "closed"
    | "scheduled"
    | "draft"
    | string
    | null;
  start_date?: string | null;
  end_date?: string | null;
}

export const employerAssessmentService = (data: EmployerAssessmentData) => {
  const request = {
    url: assessmentsURL(data.id),
    config: {
      params: {
        status: data.status,
        search: data.search || "",
        page: data.page || "",
        start_date: data.start_date ? data.start_date : undefined,
        end_date: data.end_date ? data.end_date : undefined,
        assessment_status: data.assessment_status
          ? data.assessment_status.toLowerCase()
          : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * draft service
 * @returns axios promise
 */

export interface DraftData {
  id?: string;
  search?: string;
  page?: number;
  end_date?: string | null;
}

export const draftService = (data: DraftData) => {
  const request = {
    url: assessmentsURL(data.id),
    config: {
      params: {
        status: "drafts",
        search: data.search || "",
        page: data.page || "",
        end_date: data.end_date ? data.end_date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * assessmentCarousel service
 * @returns axios promise
 */

interface AssessmentCarouselData {
  id: any;
  time_frame: "one_day" | "one_month" | "one_year";
}

export const assessmentsCarouselService = (data: AssessmentCarouselData) => {
  const request = {
    url: assessmentsCarouselURL(data.id),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * Assessment Chart service
 * @returns axios promise
 */

interface AssessmentChartData {
  id?: string;
  frequency?: string;
}

export const assessmentChartService = (data: AssessmentChartData) => {
  const request = {
    url: assessmentChartURL(data.id),
    config: {
      params: {
        frequency: data.frequency || "",
      },
    },
  };
  return getRequest(request);
};

/**
 * assessmentModal service
 * @returns axios promise
 */

export const assessmentModalService = (id?: string, id2?: string) => {
  const request = {
    url: assessmentModalURL(id, id2),
  };
  return getRequest(request);
};

/**
 * report service
 * @returns axios promise
 */

export interface ReportData {
  id?: string;
  search?: string;
  page?: number;
  report_status?: "active" | "closed" | "scheduled" | "draft" | string | null;
  start_date?: string | null;
}

export const reportService = (data: ReportData) => {
  const request = {
    url: reportsURL(data.id),
    config: {
      params: {
        search: data.search || "",
        page: data.page || "",
        assessment_status: data.report_status
          ? data.report_status.toLowerCase()
          : undefined,
        start_date: data.start_date ? data.start_date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * reportsCarousel service
 * @returns axios promise
 */

interface ReportCarouselData {
  id: any;
  time_frame: "one_day" | "one_month" | "one_year";
}

export const reportsCarouselService = (data: ReportCarouselData) => {
  const request = {
    url: reportsCarouselURL(data.id),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * Report Chart service
 * @returns axios promise
 */

interface ReportChartData {
  id?: string;
  frequency?: string;
}

export const reportChartService = (data: ReportChartData) => {
  const request = {
    url: assessmentChartURL(data.id),
    config: {
      params: {
        frequency: data.frequency || "",
      },
    },
  };
  return getRequest(request);
};

/**
 * reportsAssessed service
 * @returns axios promise
 */

export interface ReportAssessedData {
  id?: string;
  search?: string;
  page?: number;
  scores?: string | null;
  date?: string | null;
}

export const reportsAssessedService = (data: ReportAssessedData) => {
  const request = {
    url: reportsAssessedURL(data.id),
    config: {
      params: {
        assessed: "assessed" || "",
        page: data.page || "",
        search: data.search || "",
        scores: data.scores ? data.scores : undefined,
        date: data.date ? data.date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * reportsInterviewed service
 * @returns axios promise
 */

export interface ReportInterviewData {
  id?: string;
  search?: string;
  page?: number;
  scores?: string | null;
  date?: string | null;
}

export const reportsInterviewedService = (data: ReportInterviewData) => {
  const request = {
    url: reportsInterviewURL(data.id),
    config: {
      params: {
        toInterview: "toInterview" || "",
        page: data.page || "",
        search: data.search || "",
        scores: data.scores ? data.scores : undefined,
        date: data.date ? data.date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * reportsConsidered service
 * @returns axios promise
 */

export interface ReportConsideredData {
  id?: string;
  search?: string;
  page?: number;
  scores?: string | null;
  date?: string | null;
}

export const reportsConsideredService = (data: ReportConsideredData) => {
  const request = {
    url: reportsConsiderURL(data.id),
    config: {
      params: {
        consider: "consider" || "",
        page: data.page || "",
        search: data.search || "",
        scores: data.scores ? data.scores : undefined,
        date: data.date ? data.date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * reportsRejected service
 * @returns axios promise
 */

export interface ReportRejectedData {
  id?: string;
  search?: string;
  page?: number;
  scores?: string | null;
  date?: string | null;
}

export const reportsRejectedService = (data: ReportRejectedData) => {
  const request = {
    url: reportsRejectedURL(data.id),
    config: {
      params: {
        rejected: "rejected" || "",
        page: data.page || "",
        search: data.search || "",
        scores: data.scores ? data.scores : undefined,
        date: data.date ? data.date : undefined,
      },
    },
  };
  return getRequest(request);
};

/**
 * Candidate Report service
 * @returns axios promise
 */

export interface candidateReportData {
  id?: string;
  tab?: string;
}

export const candidateReportService = (data: candidateReportData) => {
  const request = {
    url: candidateReportURL(data.id),
    config: {
      params: {
        tab: data.tab || "",
      },
    },
  };
  return getRequest(request);
};

/**
 * candidates service
 * @returns axios promise
 */

export interface CandidatesData {
  progress?: string | null;
  search?: string | null;
  page?: number;
}

export const candidatesService = (data: CandidatesData) => {
  const request = {
    url: candidatesURL(),
    config: {
      params: {
        page: data.page || "",
        progress: data.progress ? data.progress.toLowerCase() : undefined,
        search: data.search || "",
      },
    },
  };
  return getRequest(request);
};

/**
 * candidatesCarousel service
 * @returns axios promise
 */

interface CandidatesCarouselData {
  time_frame: "one_day" | "one_month" | "one_year";
}

export const candidatesCarouselService = (data: CandidatesCarouselData) => {
  const request = {
    url: candidatesCarouselURL(),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * candidatesAverageTime service
 * @returns axios promise
 */

interface CandidatesAverageTimeData {
  time_frame: "one_day" | "one_month" | "one_year";
}

export const candidatesAverageTimeService = (data: CandidatesAverageTimeData) => {
  const request = {
    url: candidatesAverageTimeURL(),
    config: {
      params: {
        time_frame: data.time_frame,
      },
    },
  };
  return getRequest(request);
};

/**
 * deleteCandidateAssessment service
 * @returns axios promise
 */

export interface DeleteData {
  candidate_id?: string | null;
  assessment_id?: string | null;
}

export const deleteCandidateAssessmentService = (data: DeleteData, id: any) => {
  const request = {
    url: deleteCandidateAssessmentURL(id),
    data,
  };
  return deleteRequest(request);
};

/**
 * restartCandidateAssessment service
 * @returns axios promise
 */

export interface RestartData {
  candidate_id?: string | null;
  assessment_id?: string | null;
}

export const restartCandidateAssessmentService = (data: RestartData) => {
  const request = {
    url: candidatesRestartURL(),
    data,
  };
  return patchRequest(request);
};

/**
 * resetCandidateAssessment service
 * @returns axios promise
 */

export interface ResetData {
  candidate_id?: string | null;
  assessment_id?: string | null;
}

export const resetCandidateAssessmentService = (data: ResetData) => {
  const request = {
    url: candidatesResetTimeURL(),
    data,
  };
  return patchRequest(request);
};

/**
 * review service
 * @returns axios promise
 */

export interface ReviewsData {
  id?: string;
  id2?: string;
  search?: string;
  rating?: string;
}

export const reviewService = (data: ReviewsData) => {
  const request = {
    url: reviewsURL(data.id, data.id2),
    config: {
      params: {
        search: data.search || "",
        rating: data.rating || "",
      },
    },
  };
  return getRequest(request);
};

/**
 * Single Review service
 * @returns axios promise
 */

export const singleReviewService = (id: any, id2: any, id3?: string) => {
  const request = {
    url: singleReviewURL(id, id2, id3),
  };
  return getRequest(request);
};
